import styled from 'styled-components';

export const Container = styled.div`
    
  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 600px){
            margin-top: 70%;
            svg {

                margin-left: 35%;

            }
        }

`;

export const ButtonWhats = styled.button`
    
    border-style: none;
    width: 350px;
    margin: 10px;
    text-align: left;
    font-size: 20px;
    background: #7ADAD6;    
    
    a:hover, a:visited, a:focus, a:active, a:link, a:active{ 
        text-decoration: none; 
    }

    
    @media screen and (max-width: 600px) {
        

    }
`;
