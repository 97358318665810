import styled from 'styled-components';

export const Socialnetwork = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;

    p {
        margin: 10px 0 0 5px;
        width: 100%
    }
`;

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;

    p {
        margin: 0 0 15px 5px;
        width: 100%
    }
`;

export const ButtonList = styled.button`
    border-style: none;
    background-color: #FFF;
    margin: 10px;
    text-align: left;
    font-size: 20px;
    
    a:hover, a:visited, a:focus, a:active, a:link, a:active{ 
        text-decoration: none; 
        color: inherit; 
    }
`;