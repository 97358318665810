import React from 'react';
import Iframe from 'react-iframe';
// import { Container } from './styles';

function news() {
  return (
    <Iframe src="https://webmail.proemail.cloud/" width="100%" height={700} />
  );
}

export default news;