import React from 'react';
import Footer from '../pages/footer';

import { Switch, BrowserRouter, Route } from 'react-router-dom';

import player from '../pages/player/index';
import news from '../pages/news';
import podcast from '../pages/podcast';
import ordered from '../pages/ordered'; //whatsapp
import contact from '../pages/contact';

export default function Routes() {
    return (
        <>
      <BrowserRouter>
            <Switch>
            <Route path="/" component={news} />
            <Footer />
            </Switch>
        </BrowserRouter>
        </>
    )
}

