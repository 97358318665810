import styled from 'styled-components';

export const Menu = styled.div`

    position:fixed;
    padding: 5px 0;
    background-color: #7ADAD6;
    bottom: 0px;
    width: 100%;
    text-align: center;

    svg {
        margin: 0 3%;
        height: 40px; 
        width: 40px;
        text-anchor: middle;
    }


`;

export const Link = styled.a`
  
`;