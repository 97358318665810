import styled from 'styled-components';

export const Play = styled.div`

    img {

        display: block;
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 50%;

    }

`;


export const Controles = styled.div`

    text-align: center;
    margin-top: 30%;

    svg {
        color: #7ADAD6; 
        margin: 0 3%;
        height: 80px; 
        width: 80px;
        text-anchor: middle;
    }
`;