import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Menu } from './styles';
import { AiOutlinePlayCircle } from 'react-icons/ai';  
import { FiGlobe } from 'react-icons/fi';
import { GiOldMicrophone } from 'react-icons/gi';
import { FaWhatsapp } from 'react-icons/fa';
import { BsNewspaper } from 'react-icons/bs';


export default class Footer extends Component {

    render(){
        return (
        <Menu>
              <Link to="/"><AiOutlinePlayCircle /></Link>
            
              <Link to="/news"><BsNewspaper /></Link>
            
              <Link to="/podcast"><GiOldMicrophone /></Link>
            
              <Link to="/ordered"><FaWhatsapp /></Link>
            
              <Link to="/contact"><FiGlobe /></Link>
        </Menu>
        );
    }

}